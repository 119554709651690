<template>
  <footer class="footer">
    <div class="footer__spacer"></div>
    <div class="footer__inner">
      <div class="footer__inner-content">
        <div class="footer__sbs-logo">
          <img alt="SBS Logo" src="@/assets/images/second_bandshirt_logo.png">  
        </div>
        <ul class="footer__main-links">
          <li><router-link id="about" class="bm-item-small" to="/about">Über uns</router-link></li>
          <li><router-link id="contact" class="bm-item-small" to="/contact">Kontakt</router-link></li>
          <li><router-link id="donations" class="bm-item-small" to="/donations">Spendenübersicht</router-link></li>
          <li><router-link id="support" class="bm-item-small" to="/support">Supporter</router-link></li>
          <li><router-link id="instagram" class="bm-item-small" to="/instagram">Instagram</router-link></li>
          <li><router-link id="delivery" class="bm-item-small" to="/delivery">Versandkosten & Lieferung</router-link></li>
          <li><router-link id="widerruf" class="bm-item-small" to="/revocation">Widerruf</router-link></li>
        </ul>
        <ul class="footer__social-links">
          <li><a href="https://www.instagram.com/secondbandshirt/?hl=de" target="_blank"><img alt="Instagramm Logo" src="@/assets/images/icons/insta_icon.png"></a></li>
          <li><a href="https://www.facebook.com/secondbandshirt/" target="_blank"><img alt="FB Logo" src="@/assets/images/icons/facebook_icon.png"></a></li>
          <li><a href="https://twitter.com/sbandshirt" target="_blank"><img alt="Twitter Logo" src="@/assets/images/icons/twitter_icon.png"></a></li>
        </ul>
        
        <ul class="footer__secondary-links">
          <li><router-link id="agb" class="bm-item-small" to="/tacs#agb">AGB</router-link></li>
          <li><router-link id="datenschutz" class="bm-item-small" to="/tacs#datenschutz">Datenschutz</router-link></li>
          <li><router-link id="impressum" class="bm-item-small" to="/tacs#impressum">Impressum</router-link></li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.footer{
  a{
    text-decoration: none;
    color: white;
    font-weight: normal;
  }
  position: relative;
  &__spacer{
    width: 100%;
    height: 50px;
  }
  &__inner{
    padding: 25px;
    background-color: $color-footer;
  }
  &__inner-content{
     @media only screen and (min-width: 1300px){ 
      max-width: 1300px;
      margin: auto;
    }
  }

  ul{
    list-style: none;
    text-align: left;
    color: $color-white;
    margin:0;
    padding: 0;
  }

  &__main-links{
    font-family: 'Bebas Neue', sans-serif;
    font-size: 22px;
    li{
      font-weight: normal;
      line-height: 40px;
      @media only screen and (min-width: 1024px){ 
          display: inline-block;
          padding-right: 16px;
      }
    }
  }

  &__secondary-links{
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    li{
      font-weight: normal;
      display: inline-block;
      margin-right: 15px;
    }
  }

  &__social-links{
    text-align: left;
    li{
      font-weight: normal;
      display: inline-block;
      margin-top: 75px;
      margin-right: 20px;
      margin-bottom: 35px;
      @media only screen and (min-width: 1024px){ 
        margin-top: 35px;
      }
      img{
        height: 32px;
      }
    }
  }

  &__sbs-logo{
    position: absolute;
    right: 16px;
    top: 0px;
    margin-left: -45px;
    img{
      width: 89px;
    }
  }
}
</style>
