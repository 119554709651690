<template>
  <div class="news-box">
    <div class="news-box__inner">
      <span class="news-box__date">{{store.formatDate(newsData.postDate)}}</span>
      <h3 class="news-box__headline">{{newsData.headline}}</h3>
      <a  class="next-link">weiterlesen</a>
    </div>
  </div>
</template>

<script>
import store from "@/store/store.js";
export default {
  name: 'ShirtPreviewBox',
  props: {
    newsData: Object,
  },
  data() {
    return {
      store
    };
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  .news-box{
    position: relative;
    width: 100%;
    text-align: left;
    cursor: pointer;
    &__inner{
      background-color: $color-white;
      padding: $outer-padding;
      background-color: #ffffff;
      margin-right: $outer-padding;
    }
    &__date{
      font-family: 'Bebas Neue', sans-serif;
      font-size: 15px;
      text-transform: uppercase;
      padding: 0;
      margin: 0 0 0 12;
      font-weight: normal;
      text-align: left;
    }
    &__headline{
      padding: 0;
      padding-top: 6px;
      text-overflow: ellipsis;
      overflow: hidden; 
      white-space: nowrap;
      font-size: 27px;
    }
    &__copy{
      height: 90px;
      overflow: hidden; 
      display: block;
    }
    a{
      padding-bottom: 0!important;
      margin-bottom: 0!important;
    }
  }
</style>
