import axios from 'axios';
import store from '@/store/store.js';
import CryptoJS from 'crypto-js';

//100 sec reserve delay
const reserveDelay = 100000;
const api_url = "https://www.secondbandshirt.com/craft-cms";
//const api_url = "http://localhost";

const content_api = api_url+"/json/contentData.json";
var key = "000102030405060708090a0b0c0d0e0f";
var iv = "1011121314151617";

const queryOrderHeaders = {
  headers: {
      'Authorization': `Bearer FDsKs0-b6G9mzvSXnTAvxwv28nuX3HMa`
  }
};
const queryProductsHeaders = {
  headers: {
      'Authorization': `Bearer p1clEzrS4wcTQyjFR5qLUgO81BDg8teX`
  }
};

class ApiService {
    //Get products data from db
    loadProductsData(loadContent) {    
      
      this.decryptUserData("Matthias");
      
      var queryGetProducts = {
        query: `{
          entries(section: "produkte"){
            ... on produkte_produkte_Entry {
                id
                artikelnummer
                dateCreated
                produktName
                grosse
                preis
                produktBild {
                  id
                  url(transform: "productImageTransform")
                  filename
                }
                produktHersteller
                produktBildUrl
                zustand
                kategorie
                reserviert
                gekauft
                verkauft
            }
        }
      }`
      };

      
      axios
      .post(api_url+"/api", queryGetProducts, queryProductsHeaders)
      .then((response) => {
        if (response.data.data.errors) {
          // do something
        } else { 
          var productsDataSorted = response.data.data.entries;
          var productsData = [];
          for(var i = 0; i < productsDataSorted.length; i++){
            if(productsDataSorted[i].gekauft != true && productsDataSorted[i].verkauft != true){
              productsData.push(productsDataSorted[i]);
            }
          }

          //remove duplicates
          productsData = productsData.filter((value, index, self) =>
            index === self.findIndex((t) => (
              t.produktName === value.produktName &&
              t.zustand === value.zustand &&
              t.grosse === value.grosse &&
              t.preis === value.preis &&
              t.zustand === value.zustand
            ))
          )

          //for preview start screen
          store.productsData = productsData;
          store.productsDataShort[0] = store.productsData[0];
          store.productsDataShort[1] = store.productsData[1];
          store.productsDataShort[2] = store.productsData[2];
          store.productsDataShort[3] = store.productsData[3];
          store.productsDataShort[4] = store.productsData[4];
          store.productsDataShort[5] = store.productsData[5];
          if(loadContent)
            this.loadContentData();
        }
      })
      .catch(error => {
        console.log(error);
      });
    }

    //Get products data from db
    loadContentData() {
      axios
      .post(content_api)
      .then((response) => {
        if (response) {
          // do something
          store.dataLoaded = true;

          store.contentData = response.data.pages.data.entries;
          store.cityGroups = this.filterByName(response.data.citygroups.data.entries, true);
          store.news = response.data.news.data.entries;
          store.newsShort[0] = store.news[0];
          store.newsShort[1] = store.news[1];
          store.newsShort[2] = store.news[2];
          store.newsShort[3] = store.news[3];
          store.projects = response.data.projects.data.entries;
          store.press = response.data.press.data.entries;
          store.donations =  response.data.donations.data.entries;

          if(response.data.shopSettings.data.entries[0].shopEnabled){
            store.enableShop = true;
          }else{
            store.enableShop = false;
          }
          store.disableShopInfo = response.data.shopSettings.data.entries[0].shopInfoAusgeblendet;

          //save dontations in user model
          if(store.userData.payChoices.length == 0){
            for(var i = 0; i < store.donations.length; i++){
              var donation =  store.donations[i];
              if(donation.inShopauswahl){
                var newDonation = {
                  organisationName : donation.organisation,
                  betrag: 0,
                  betragInProzent: 100
                };
                store.userData.payChoices.push(newDonation);
              }
            }  
          }
          
          store.collectGroups = this.filterByName(response.data.collectors.data.entries, false);
          store.sellerGroups = response.data.sellers.data.entries;
          store.instagramGroups = response.data.instagram.data.entries;
        }
      })
      .catch(error => {
        console.log(error);
      });
    }

    filterByName(listObject, byName){
      var newList;
      if(byName){
        newList = listObject.sort(function(a, b) {
          var nameA = a.organisationName.toUpperCase();
          var nameB = b.organisationName.toUpperCase();
          return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
        });  
      }else{
        newList = listObject.sort(function(a, b) {
          var nameA = a.organisationStadt.toUpperCase();
          var nameB = b.organisationStadt.toUpperCase();
          return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
        });
      }
      
      return newList;
    }

    //Reserve Product 
    reserveProduct(article){
      var milliseconds = new Date().getTime();
      var queryReserveProduct = {
        query: `mutation UpdateProdukte {
          save_produkte_produkte_Entry(id: `+article.id+`, reserviert: "`+milliseconds+`") {
            id
            reserviert
          }
        }`
      };

      axios
          .post(api_url+"/api", queryReserveProduct, queryOrderHeaders)
          .then((response) => {
            if (response.data) {
              store.firePopup = {
                title: "Gute Wahl!",
                text: "Der Artikel wurde deinem Warenkorb hinzugefügt.",
                type: "success",
                timer: 5000
              };
            }
          })
          .catch(error => {
            console.log(error);
          });
    }


    //Buy Product 
    buyProduct(article){
      var queryReserveProduct = {
        query: `mutation UpdateProdukte {
          save_produkte_produkte_Entry(id: `+article.id+`, gekauft: true) {
            id
            gekauft
          }
        }`
      };

      axios
          .post(api_url+"/api", queryReserveProduct, queryOrderHeaders)
          .then((response) => {
            if (response.data) {
              //console.log("Reserve product");
            }
          })
          .catch(error => {
            console.log(error);
          });
    }

    //Reserve Product 
    checkReserveProduct(article){
      store.userData.products.push(article);

      var queryReserveIsProduct = {
        query: `query GetProdukt {
          entry(id: "`+article.id+`") {
            ... on produkte_produkte_Entry {
              id
              reserviert
            }
          }
        }`
      };

      axios
          .post(api_url+"/api", queryReserveIsProduct, queryOrderHeaders)
          .then((response) => {
            if (response.data) {
              var milliseconds = new Date().getTime();
              
              console.log(response.data.data.entry.reserviert+" / "+milliseconds);

              // do something
              if(parseInt(response.data.data.entry.reserviert) + reserveDelay > milliseconds){
                store.firePopup = {
                  title: "Artikel ist leider schon reserviert.",
                  text: "Der Artikel wurde schon von einem anderen Nutzer reserviert. Versuche es später noch einmal.",
                  type: "error",
                  timer: 5000
                };

                for(var i = 0; i < store.userData.products.length; i++){
                  if(store.userData.products[i].artikelnummer == article.artikelnummer){
                    store.userData.products.splice(i, 1);   
                  }
                }
              }else{
                this.reserveProduct(article);
              }
            }
          })
          .catch(error => {
            console.log(error);
          });
    }

    //Save Order to DB
    saveOrderData(){
      if(store.userData.products.length > 0){
        for(var i = 0; i < store.userData.products.length; i++){
          store.userData.productsList += store.userData.products[i]+",";
        }

        var donationsList = "";
        for(var j = 0; j < store.userData.payChoices.length; j++){
          donationsList += store.userData.payChoices[j].organisationName +": "+ store.userData.payChoices[j].betrag+" € | ";
        }

        var articlesList = "";
        for(var k = 0; k < store.userData.products.length; k++){
          articlesList += store.userData.products[k].artikelnummer+" | ";
        }

        var queryOrderData = {
          query: `mutation SaveShopBestellung {
            save_shopBestellungen_shopBestellungen_Entry(authorId: 1, spendenVerteilung: "`+donationsList+`", artikelNummern: "`+articlesList+`", email: "`+this.decryptUserData(store.userData.email)+`", plz: "`+this.decryptUserData(store.userData.plz)+`", sessionId: "`+store.userData.sessionId+`", stadt: "`+this.decryptUserData(store.userData.city)+`", strasse: "`+this.decryptUserData(store.userData.street)+`", vorname: "`+this.decryptUserData(store.userData.name)+`", zahlungsart: "`+store.userData.payMethod+`", zusatzinfos: "`+this.decryptUserData(store.userData.additionalInformation)+`", nachname: "`+this.decryptUserData(store.userData.surname)+`", porto: "`+store.userData.porto.toFixed(2)+`") {
              id
            }
          }`
        };
      
        axios
          .post(api_url+"/api", queryOrderData, queryOrderHeaders)
          .then((response) => {
            if (response.data.errors) {
              // do something
              console.log("Can not save Order");

              store.firePopup = {
                title: "Bestellung fehlgeschlagen.",
                text: "Deine Bestellung konnte nicht verarbeitet werden. Probiere es gerne noch einmal.",
                type: "error",
                timer: 5000
              };
            } else {
              //console.log("Order was saved");
              for(var k = 0; k < store.userData.products.length; k++){
                this.buyProduct(store.userData.products[k]);
              }

              //reset user data
              store.resetUserData();

              store.firePopup = {
                title: "Vielen Dank!",
                text: "Deine Bestellung ist bei uns eingegangen. Du erhälst in kürze eine Bestätigungsemail",
                type: "success",
                timer: 300000
              };
            }
          })
          .catch(error => {
            console.log(error);
          });
      }else{
        store.firePopup = {
          title: "Du hast keine Artikel ausgewählt.",
          text: "Deine Bestellung konnte nicht verarbeitet werden. Probiere es gerne noch einmal.",
          type: "error",
          timer: 5000
        };
      }
    }

    decryptUserData(userDataString){
      let cipher = CryptoJS.AES.encrypt(userDataString, CryptoJS.enc.Utf8.parse(key), {
          iv: CryptoJS.enc.Utf8.parse(iv),
          padding: CryptoJS.pad.Pkcs7,
          mode: CryptoJS.mode.CBC
      });

      return cipher.toString();
    }
  }
  export default new ApiService();