<template>
  <div id="app">
    <Header/>
    <transition
        name="fade"
        mode="out-in"
        @beforeLeave="beforeLeave"
        @enter="enter"
        @afterEnter="afterEnter"
      >
    <router-view class="content" v-if="store.dataLoaded"/>
    </transition>
    <Footer/>

    <div v-bind:class="{ app_loader_inactive: store.dataLoaded}" class="app_loader">
      <div class="app_loader__inner">
        <img class="app_loader__inner_loader" alt="Loader Animations" src="@/assets/images/loader_animation.gif">
        <img class="app_loader__inner_logo" alt="SBS Logo" src="@/assets/images/second_bandshirt_logo.png">  
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

import apiService from '@/services/apiService.js';
import store from '@/store/store.js';

export default {
  metaInfo: {
    title: 'Second Bandshirt',
    htmlAttrs: {
      lang: 'de',
      amp: true
    }
  },
  name: 'App',
  components: {
    Header,
    Footer
  },
  data() {
    return {
      prevHeight: 0,
      store,
    };
  },
  methods: {
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = 'auto';
    },
    padNumber(number) {
      number = number.toString();

      while(number.length < 4) {
          number = "0" + number;
      }

      return number;
  }
  },
  computed: {
    firePopup () {
      return this.store.firePopup
    }
  },
  watch: {
    $route() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    },
    firePopup () {
      this.$fire({
      title:  this.store.firePopup.title,
      text:  this.store.firePopup.text,
      type:  this.store.firePopup.type,
      timer:  this.store.firePopup.timer
      });
    }
  },
  mounted () {
    this.store.resetUserData();
    apiService.loadProductsData(true);
    var milliseconds = new Date().getTime();
    this.store.sessionStart = milliseconds;
    this.store.userData.sessionId = this.padNumber(Math.round(Math.random()*1000))+"-"+milliseconds;


    /* eslint-disable */
      console.log(`%c
░██████╗███████╗░█████╗░░█████╗░███╗░░██╗██████╗░
██╔════╝██╔════╝██╔══██╗██╔══██╗████╗░██║██╔══██╗
╚█████╗░█████╗░░██║░░╚═╝██║░░██║██╔██╗██║██║░░██║
░╚═══██╗██╔══╝░░██║░░██╗██║░░██║██║╚████║██║░░██║
██████╔╝███████╗╚█████╔╝╚█████╔╝██║░╚███║██████╔╝
╚═════╝░╚══════╝░╚════╝░░╚════╝░╚═╝░░╚══╝╚═════╝░

██████╗░░█████╗░███╗░░██╗██████╗░░██████╗██╗░░██╗██╗██████╗░████████╗
██╔══██╗██╔══██╗████╗░██║██╔══██╗██╔════╝██║░░██║██║██╔══██╗╚══██╔══╝
██████╦╝███████║██╔██╗██║██║░░██║╚█████╗░███████║██║██████╔╝░░░██║░░░
██╔══██╗██╔══██║██║╚████║██║░░██║░╚═══██╗██╔══██║██║██╔══██╗░░░██║░░░
██████╦╝██║░░██║██║░╚███║██████╔╝██████╔╝██║░░██║██║██║░░██║░░░██║░░░
╚═════╝░╚═╝░░╚═╝╚═╝░░╚══╝╚═════╝░╚═════╝░╚═╝░░╚═╝╚═╝╚═╝░░╚═╝░░░╚═╝░░░
---------------------------------------------------------------------
Hey Devs! 
Be gentle ;) 
We are all doing this in our free time! If you find some security issues or bugs contact us under: 
admin@secondbandshirt.com
---------------------------------------------------------------------
`, "font-family:monospace");
  }
}
</script>

<style lang="scss">
html{
  overflow-x: hidden;
  width: 100%;
  margin: 0;
  padding: 0;
}
body{
    margin: 0;
    padding: 0;
    background-color: $color-background;
    overflow-x: hidden;
}

.content{
  max-width: 1300px;
  margin: auto;
  min-height: 640px;
  overflow-x: hidden;
  overflow-y: hidden;

  a{
    padding-left: 0;
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  overflow-x: hidden;
}

//loader
.app_loader{
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  text-align: center;
  background-color: white;
  z-index: 20;
  transition: all, 0.3s;
  overflow: hidden;
  &__inner{
    position: absolute;
    width: 200px;
    height: 200px;
    left: 50%;
    top: 50%;
    margin-left: -100px;
    margin-top: -100px;
    &_logo{
      position: absolute;
      left: 50px;
      top: 50px;
      width: 100px;
    }
  }
}
.app_loader_inactive{
  animation: fadeOutLoader 1s;
  animation-fill-mode: forwards;
}
@keyframes fadeOutLoader { 
    0%   {left:0; opacity: 1}
    50%  {left:0; opacity: 0}
    100% {left:100%; opacity: 0}
}

//overall components
.link_secondary{
  background: url('assets/images/icons/link-arrow.png');
  background-repeat: no-repeat;
  background-size: 30px 15px;
  background-position: 70px 16px;
  text-align: right;
  text-decoration: none;
  color: $color-primary;
  text-transform: uppercase;
  padding-right: 40px;
  line-height: 50px;
  font-family: 'Bebas Neue', sans-serif;
  font-size: 21px;
  font-weight: normal;
}
.link_secondary_back{
  background: url('assets/images/icons/link-arrow-reverse.png');
  padding-left: 40px;
  text-align: left;
  background-position: 0px 5px;
  background-repeat: no-repeat;
  background-size: 30px 15px;
}

.button{
  position: relative;
  background-color: $color-primary;
  min-width: 145px;
  width: 145px;
  height: 48px;
  text-align: center;
  font-family: 'Bebas Neue', sans-serif;
  font-size: 26px;
  line-height: 55px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
  font-weight: normal;
  color: white;
  display: block;
  cursor: pointer;
  img{
    position: absolute;
    left: 14px;
    top: 13px;
    width: 24px;
  }
  &:hover {
    margin-left: 4px;
    margin-top: 4px;
    -webkit-box-shadow: 2px 2px 0px 0px rgb(0, 0, 0);
    -moz-box-shadow: 2px 2px 0px 0px rgb(0, 0, 0);
    box-shadow: 2px 2px 0px 0px rgb(0, 0, 0);
  }


}

.swal2-actions{
  button{
    position: relative;
    background-color: $color-primary!important;
    min-width: 145px!important;
    width: 145px!important;
    height: 48px!important;
    text-align: center!important;
    font-family: 'Bebas Neue', sans-serif!important;
    font-size: 26px!important;
    line-height: 55px!important;
    text-transform: uppercase!important;
    padding: 0!important;
    margin: 0!important;
    font-weight: normal!important;
    color: white!important;
    border-radius: 0px!important;
    cursor: pointer!important;
    -webkit-box-shadow: 4px 4px 0px 0px rgb(0, 0, 0);
    -moz-box-shadow: 4px 4px 0px 0px rgb(0, 0, 0);
    box-shadow: 4px 4px 0px 0px rgb(0, 0, 0);

     &:hover {
      margin-left: 4px;
      margin-top: 4px;
      -webkit-box-shadow: 2px 2px 0px 0px rgb(0, 0, 0);
      -moz-box-shadow: 2px 2px 0px 0px rgb(0, 0, 0);
      box-shadow: 2px 2px 0px 0px rgb(0, 0, 0);
    }
    &:focus {
      outline: none;
    }
  }
  h2{
    font-family: 'Bebas Neue', sans-serif!important;
  }
}
.swal2-content{
  color: black!important;
  font-family: 'Bebas Neue', sans-serif!important;
}
.swal2-title{
  color: black!important;
  font-family: 'Bebas Neue', sans-serif!important;
}
.swal2-show {
    padding-bottom: 40px;
}

//SBS pagination
.pagination > li:first-child > a, .pagination > li:first-child > span {
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 0!important;
  border: 0!important;
  background: url('assets/images/icons/link-arrow-reverse.png');
  background-repeat: no-repeat;
  background-size: 30px 15px;
  background-position: 8px 12px;
  font-size: 0;
  outline: none;
}
.pagination > li:last-child > a, .pagination > li:last-child > span {
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 0!important;
  border: 0!important;
  background: url('assets/images/icons/link-arrow.png');
  background-repeat: no-repeat;
  background-size: 30px 15px;
  background-position: -8px 12px;
  font-size: 0;
  outline: none;
}
.pagination > li > a, .pagination > li > span {
    width: 30px;
    height: 30px;
    padding: 0!important;
    margin-left: 6px!important;
    line-height: 35px!important;
    color: $color-primary!important;
    background-color: #fff;
    border: 3px solid $color-primary!important;
    border-radius: 0;
    font-family: 'Bebas Neue', sans-serif;
    font-size: 22px;
    font-weight: normal;
    outline: none;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
    color: #fff!important;
    background-color: $color-primary!important;
    border-color: $color-primary!important;
    outline: none;
}
.pagination > .disabled > span, .pagination > .disabled > span:hover, .pagination > .disabled > span:focus, .pagination > .disabled > a, .pagination > .disabled > a:hover, .pagination > .disabled > a:focus {
    outline: none;
}
.pagination a{
  text-align: center;
}

//animations
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.7s;
  transition-property: height, opacity;
  transition-timing-function: ease;
  overflow: hidden;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}
a{
  color: $color-primary;
  text-align: left;
  display: block;
  font-weight: bold;
}

/*
Overall Page
*/
.overallpage{
    padding-top: 120px;
    padding-bottom: 20px;
    h2{
      margin-bottom: 0;
      font-size: 42px;
      text-align: left;
      padding: 16px;
      color: black;
      padding-bottom: 0;
    }
    h4{
      margin-bottom: 0;
      font-size: 22px;
      text-align: left;
      padding: 16px;
      color: black;
      padding-bottom: 0;
      padding-top: 32px;
    }
     p{
      font-size: 17px;
      color: black;
      font-weight: normal;
      text-align: left;
      padding: 16px;
      padding-top: 0;
    }
    a{
      padding: 16px;
      padding-top: 8px;
      padding-left: 0px;
    }
    .group{
      padding-top: 0px;
      padding-bottom: 32px;
      p{
        padding-bottom: 0;
        margin-bottom: 0;
        padding-top: 5px;
        margin-top: 0;
      }
      a{
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
    li{
      text-align: left;
    }
    .button{
      width: 290px;
      display: block;
      margin-top: 10px;
      margin-left: 16px;
    }
}


/* Projects template */
.projects{
  padding-top: 120px;
  h2{
    margin-bottom: 0;
    font-size: 42px;
    text-align: left;
    padding: 16px;
  }
  .project{
  min-height: 620px;
    &__header_image{
      margin-top: 0;
      padding-top: 0;
      height: 320px;
      overflow: hidden;
      @media only screen and (min-width: 1024px){ 
        max-height: 400px;
        overflow: hidden;
      }
      img{
        position: relative;
        width: 100%;
        @media only screen and (min-width: 1024px){ 
          margin-top: -20%;
        }
      }
      z-index: 0;
    }
    &__header_content_box{
      position: relative;
      background-color: $color-primary;
      padding: 10px;
      padding-top: 20px;
      margin-top: -4px;
      margin-top: -40px;
      margin-left: 2.5%;
      width: 90%;
      max-width: 640px;
      @media only screen and (min-width: 1024px){ 
        margin-top: -40px;
        margin-left: 10%;
        margin-bottom: 48px;
        padding: 24px;
      }
      h2{
        color: $color-white;
        text-align: left;
        font-size: 36px;
        padding: 0;
        @media only screen and (min-width: 1024px) {
          font-size: 52px;
        }
        
      }
      h4{
        color: $color-white;
        @media only screen and (min-width: 1024px) {
          margin-bottom: 12px;
        }
      }
    }
    p{
      font-size: 17px;
      line-height: 1.3em;
      color: black;
      font-weight: normal;
      text-align: left;
    }
    &__content{
      display: block;
      padding: 16px;
      max-width: 640px;
      margin: auto;
      figure{
        padding: 0;
        margin: 0;
        padding-bottom: 16px;
        img{
          max-width: 100%;
        }
      }
      li{
        text-align: left;
      }
    }
    a{
      padding: 16px;
      display: contents;
    }
  }
}


.projects_list{
  padding-top: 120px;
  .project{
    width: 100%;
    max-width: 670px;
    margin: auto;
    &:hover {
    -webkit-box-shadow: 4px 4px 0px 0px rgb(0, 0, 0);
    -moz-box-shadow: 4px 4px 0px 0px rgb(0, 0, 0);
    box-shadow: 4px 4px 0px 0px rgb(0, 0, 0);
    }

    img{
      display: none;
    }

    padding: 16px;
    margin-bottom: 26px;
    cursor: pointer;
    @media only screen and (min-width: 1024px){ 
      width: 50%;
      background-color: white;
      margin: auto;
      margin-bottom: 48px;
      padding: 48px;
    }
    &__header_image{
      margin-top: 0;
      padding-top: 0;
      min-height: 120px;
      img{
        position: relative;
        width: 100%;
      }
      z-index: 0;
    }
    &__header_content{
      position: relative;
      h2{

        text-align: left;
        font-size: 36px;
        padding: 0;
        margin-top: 0px;

        @media only screen and (min-width: 1024px){ 
          font-size: 48px;
        }
      }
      h4{
        font-size: 18px;
        margin-bottom: 4px;
        @media only screen and (min-width: 1024px){ 
          font-size: 21px;
        }

      }
    }
    &__content{
        display: block;
        padding: 0;
      }
    p{
      font-size: 17px;
      line-height: 1.4em;
      color: black;
      font-weight: normal;
      text-align: left;
      padding: 0;
    }
  }
  a{
    padding: 0;
    text-decoration: none;
    display: contents;
    color: black;
    pointer-events: none;
    font-weight: normal;
    cursor: pointer;
  }
}
.next-link{
  font-family: 'Bebas Neue', sans-serif;
  display: block!important;
  padding-bottom: 16px!important;
  padding-top: 8px!important;
  color: $color-primary!important;
  pointer-events: auto!important;
  cursor: pointer!important;
  text-transform: uppercase;
  text-decoration: underline !important;
  letter-spacing: 0.2
  font-size 32px;
  font-weight: 400;

  &:hover {
    text-decoration: none !important;
  }
}



.marker_adress{
  padding-left: 50px;
  margin-bottom: 20px;
  padding-top: 10px;
}
.map_link{
  cursor: pointer!important;
  position: relative;
  display: block;
  span{
    color: $color-primary!important;
    font-weight: bold;
  }
}
.sellers_subline{
      font-size: 17px;
      max-width: 740px;
      margin: auto;
      line-height: 28px;
      margin-bottom: 32px;
    }
  .city_image{
    width: 100%;
    text-align: left;
    display: block;
    padding: 16px;
    padding-left: 0;
    padding-right: 0;
  }
  .project_single{
    background-color: white;
      cursor: default!important;
      &:hover {
      -webkit-box-shadow: 0px 0px 0px 0px rgb(0, 0, 0)!important;
      -moz-box-shadow: 0px 0px 0px 0px rgb(0, 0, 0)!important;
      box-shadow: 0px 0px 0px 0px rgb(0, 0, 0)!important;
    }
    .project__header_content{
      text-align: left;
    }
    img{
      display: block!important;
    }
    a{
      padding-bottom: 0!important;
      pointer-events:auto;
      position: relative;
      display: block;
      img{
        position: absolute;
        left: 0;
        top: 0;  
      }
    }
  }
  .close_link{
    position: fixed;
    right: 16px;
    top: 66px;
    background-color: white;
    padding: 10px!important;
    font-size: 25px;
    text-decoration: none!important;
    line-height: 25px;
    padding-bottom: 6px!important;
  }
</style>
