<template>
  <div v-if="store.dataLoaded" class="home">
    <!-- header -->
    <div class="home__header_image">
      <img alt="Header Image" src="@/assets/images/header_home.png">  
    </div>
    <div v-if="store.contentData" class="home__header_content shadow-box">
      <h2>{{getContentData('home').headline}}</h2>
      <span v-html="getContentData('home').pageContent"></span>
  </div>


  <!-- shirts carousel -->
  <div class="home__shirts_slider">
    <div v-if="!store.enableShop" class="shop__disabled_overlay">
      <div class="shop__disabled_inner">
        <h3>Wir machen Urlaub!</h3>
        <img alt="Corona Icon" src="@/assets/images/icons/holiday_icon.png">  
        <p>{{store.disableShopInfo}}<br>
Die Wiedereröffnung werden wir dann über Facebook, Instagram und co ankündigen.<br><br>
In der nächsten Zeit findet ihr uns außerdem hier: <router-link id="sellers" to="/sellers">Aktuelle Verkaufsstände</router-link><br><br>

Schaut also einfach später noch einmal vorbei!
<br><br>
🖤
</p>
      </div>
    </div>
    <div class="home__shirts_slider_bg" ></div>
    <h3>Neueste Shirts</h3>
    <router-link v-if="store.enableShop" class="link_secondary home__shirts_shoplink" to="/shop">
        <span>Zum Shop</span>
    </router-link>
    <router-link v-if="!store.enableShop" class="link_secondary home__shirts_shoplink" to="">
        <span>Zum Shop</span>
    </router-link>
    
    <div class="home__shirts_slider_mobile">
      <VueSlickCarousel :arrows="false" :dots="false" :slidesToShow="2">
        <div v-for="product in store.productsDataShort" :key="product.id">
          <div v-on:click="showProductDetail(product.artikelnummer)">
            <ShirtPreviewBox :productData=product></ShirtPreviewBox>
          </div>
        </div>
      </VueSlickCarousel> 
    </div>
    <div class="home__shirts_slider_desktop">
      <VueSlickCarousel :arrows="false" :dots="false" :slidesToShow="5">
        <div v-for="product in store.productsDataShort" :key="product.id">
          <div v-on:click="showProductDetail(product.artikelnummer)">
            <ShirtPreviewBox :productData=product></ShirtPreviewBox>
          </div>
        </div>
      </VueSlickCarousel>
    </div>
  </div>

  <!-- news carousel -->
  <div v-if="store.news" class="home__news_slider">
    <h3>Aktuelles</h3>
    <div class="home__news_slider_mobile">
      <VueSlickCarousel :arrows="false" :dots="false" :slidesToShow="1.5" :infinite ="false">
        <div v-for="(news) in store.newsShort" :key="news.id">
          <div v-on:click="showNewsDetail(news.id)">
          <NewsBox :newsData=news></NewsBox>
          </div>
        </div>
      </VueSlickCarousel>
    </div>
    <div class="home__news_slider_desktop">
      <VueSlickCarousel :arrows="false" :dots="false" :slidesToShow="3" :infinite ="false">
        <div v-for="news in store.newsShort" :key="news.id">
          <div v-on:click="showNewsDetail(news.id)">
          <NewsBox :newsData=news></NewsBox>
          </div>
        </div>
      </VueSlickCarousel>
    </div>
  </div>
  


  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

import ShirtPreviewBox from "@/components/ShirtPreviewBox.vue";
import NewsBox from "@/components/NewsBox.vue";

import store from '@/store/store.js';

export default {
  name: 'Home',
  components: {
    ShirtPreviewBox,
    VueSlickCarousel,
    NewsBox,
  },
  data() {
    return {
      store,
    };
  },
  methods: {
    showProductDetail(productIndex){
      if(this.store.enableShop){
        this.store.selectedProductIndex = productIndex;
        this.$router.push({ path: 'productDetail', query: { shirtId: productIndex } })
      }
    },
    getContentData: function (dataHandle) {
      var contentModel = null;
      for(var i = 0; i < this.store.contentData.length; i++){
        if(this.store.contentData[i].pageHandle == dataHandle){
          contentModel = this.store.contentData[i];
        }
      }
      return contentModel;
    },
    showNewsDetail(newsIndex){
      this.store.selectedNewsIndex = newsIndex;
      this.$router.push({ path: 'newsDetail', query: { newsId: newsIndex } })
    },
  }
}
</script>

<style lang="scss">
  .home{
    color: $color-text;
    min-height: 1000px;
    &__header_image{
      padding-top: $header_height;
      @media only screen and (min-width: 600px){ 
        height: 400px;
        overflow: hidden;
      }
      @media only screen and (min-width: 1024px){ 
        height: 450px;
        overflow: hidden;
      }
      img{
        position: relative;
        width: 100%;
        @media only screen and (min-width: 1024px){ 
          margin-top: -10%;
        }
      }
      z-index: 0;
    }
    &__header_content{
      position: relative;
      background-color: $color-primary;
      padding: 10px;
      padding-top: 20px;
      margin: auto;
      margin-top: -25%;
      max-width: 90%;
      @media only screen and (min-width: 600px){ 
        margin: auto;
        margin-top: -80px;
        max-width: 600px;
      }
      @media only screen and (min-width: 1224px){ 
        width: 410px;
        text-align: center;
        position: absolute;
        left: 50%;
        margin-left: 160px;
        margin-top: -230px;
      }

      h2{
        color: $color-white;
        position: absolute;
        left: 0;
        width: 100%;
        top: -25px;
        font-size: 45px;
        @media only screen and (min-width: 370px){ 
          top: -31px;
          font-size: 55px;
        }
      }
      p{
        font-size: 14px;
        color: $color-white;
        font-weight: normal;
        @media only screen and (min-width: 370px){ 
         font-size: 17px;
        }
      }
    }
    &__shirts_shoplink{
      position: absolute;
      top: 13px;
      right: 16px;
    }
    &__shirts_slider{
      margin-top: $outer-padding;
      margin-bottom: $outer-big-padding;
      position: relative;
      h3{
        display: block;
        position: relative;
      }
      .shirt-box__name{
        padding-left: 16px;
      }
      .shirt-box__price{
        padding-left: 16px;
      }
    }
    &__shirts_slider_bg{
      position: absolute;
      width: 80%;
      height: 100%;
      left:0;
      top:0;
      background-color: $color-white;
      @media only screen and (min-width: 1024px){ 
        width: 100%;
      }
    }
    &__news_slider{
      margin-top: $outer-padding;
      margin-bottom: $outer-big-padding;
    }

    .home__shirts_slider_mobile{
      display: block;
      @media only screen and (min-width: 1024px){ 
        display: none;
      }
    }
    .home__shirts_slider_desktop{
      display: none;
      margin-left: $outer-padding;
      @media only screen and (min-width: 1024px){ 
        display: block;
      }
    }

    .home__news_slider_mobile{
      display: block;
      @media only screen and (min-width: 1024px){ 
        display: none;
      }
    }
    .home__news_slider_desktop{
      display: none;
      @media only screen and (min-width: 1024px){ 
        display: block;
      }
    }
  }
</style>
