<template>
  <div class="shirt-box">
    <div v-if="productData.produktBild" class="shirt-box__inner">
      <img class="shirt-box__loader" alt="Shirt Loader" src="@/assets/images/loader_small_animation.gif">
      <img class="shirt-box__image" alt="Shirt Preview" v-bind:src="getImageUrlOfProduct(productData)">  
      <span class="shirt-box__size">{{convertSizeLabel(productData.grosse)}}</span>
      <h4 class="shirt-box__name">{{productData.produktName}}</h4>
      <span class="shirt-box__price">{{productData.kategorie}} <br>{{parseFloat(productData.preis).toFixed(2)}}€</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShirtPreviewBox',
  props: {
    productData: Object,
  },
  methods: {
    getImageUrlOfProduct (product) {
      var productUrl = "";
      
      if(product.produktBild.length == 0){
        if(product.produktBildUrl.split(".jp")[1] == "eg"){
          productUrl = product.produktBildUrl.split(".jpeg")[0]+"-300x300.jpeg";
        }else{
          productUrl = product.produktBildUrl.split(".jpg")[0]+"-300x300.jpg";
        }
      }else{
        if(product.produktBild[0]){
          productUrl = product.produktBild[0].url;
        }else{
          //fallback image
          productUrl = "http://localhost:8080/img/header_home.28a0dd56.png";
        }
          
      }
      return productUrl;
    },
    convertSizeLabel(sizeName){
      var name = sizeName;
      if(name == "youthm"){
        name = "y m";
      }
      if(name == "youthl"){
        name = "y l";
      }
      return name;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  .shirt-box{
    color: $color-text !important;
    position: relative;
    width: 100%;
    cursor: pointer;
    &__inner{
      margin-right: 8px;
      position: relative;
      margin-bottom: 48px;
    }
    &__image{
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
    }
    &__loader{
      position: relative;
      width: 100%;
    }
    &__name{
      padding-top: 5px;
      padding-bottom: 5px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      
    }
    &__price{
      font-size: 16px;
      text-align: left;
      display: block;
      padding-bottom: 15px;
    }
    &__size{
      background-color: $color-primary;
      width: 24px;
      height: 24px;
      color: $color-white;
      display: block;
      position: absolute;
      right: 8px;
      top: 8px;
      font-size: 16px;
      line-height: 25px;
      text-transform: uppercase;
      font-family: 'Bebas Neue', sans-serif;
      font-weight: normal;
      overflow: hidden;
    }
  }
</style>
